import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../../../../../../../helpers/dataService";
import Loader from "../../../../../../../../../../common/Loader";
import Control from "../../../../../../../../../../common/Control";

const AvecEcheancierForm = ({ contrat, onToggleAvecEcheancier }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAvecEcheancier, setIsAvecEcheancier] = useState(
    contrat.avecEcheancier
  );
  const [isAvecMensualisation, setIsAvecMensualisation] = useState(
    contrat.avecMensualisation
  );

  useEffect(() => {
    setIsAvecEcheancier(contrat.avecEcheancier);
  }, [contrat.avecEcheancier]);
  useEffect(() => {
    setIsAvecMensualisation(contrat.avecMensualisation);
  }, [contrat.avecMensualisation]);

  const toggleAvecEcheancier = () => {
    const confirmText = isAvecEcheancier
      ? "Êtes-vous sûr de vouloir désactiver l'échéancier ? Cela aura pour effet de permettre au client d'être notifé par email pour procéder au réglement de la prime depuis son espace client."
      : "Êtes-vous sûr de vouloir activer l'échéancier ? Le client ne sera plus notifié par email et ne pourra plus procéder au réglement de la prime depuis son espace.";
    if (window?.confirm(confirmText)) {
      setIsLoading(true);
      dataService.patch(
        `policies/${contrat.id}/avec-echeancier`,
        { avecEcheancier: !isAvecEcheancier },
        (data) => {
          setIsAvecEcheancier(!isAvecEcheancier);
          onToggleAvecEcheancier();
        },
        (err) => {
          toast.error(
            "Une erreur est survenue lors de la modification de l'échéancier"
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    }
  };

  const echeancierText = isAvecEcheancier
    ? `L'échéancier est activé sur ce contrat, le client ne reçoit pas les
          notifications email pour le paiement de sa prime et ne peut pas les
          régler depuis son espace client.`
    : `L'échéancier est désactivé sur ce contrat, le client reçoit les
            notifications email pour le paiement de sa prime et peut les régler
            depuis son espace client.`;

  const mensualisationText = isAvecMensualisation
    ? `La mensualisation est activée sur ce contrat, le client ne reçoit pas les notifications email pour le paiement de sa prime et ne peut pas les régler depuis son espace client.`
    : `La mensualisation est désactivée sur ce contrat, le client reçoit les notifications email pour le paiement de sa prime et peut les régler depuis son espace client.`;
  return (
    <>
      <div className="d-flex align-items-center">
        <button
          className={`btn bg-transparent d-flex align-items-center border-0 ${
            isAvecEcheancier ? "text-primary" : "text-default"
          } p-0`}
          style={{ cursor: "auto" }}
          disabled
        >
          <>
            <i
              className={`mr-2 ${
                isAvecEcheancier
                  ? "fa fa-check-circle"
                  : "far fa-circle text-black-50"
              }`}
            />
            <span style={{ whiteSpace: "nowrap" }}>
              {isAvecEcheancier ? "Avec échéancier" : "Sans échéancier"}
            </span>
          </>
        </button>
        <button
          className="btn btn-default px-0 px-2 bg-transparent border-0"
          onClick={() => toast.info(echeancierText, { autoClose: 15000 })}
        >
          <i className="fa fa-question-circle" />
        </button>
        <div data-priv="bo_modifiercontratsecheancier">
          <Control
            type="checkbox"
            checked={isAvecEcheancier}
            change={() => toggleAvecEcheancier()}
            noMarginOnFormGroup
            noPaddingOnFormGroup
            margin="m-0"
            noBg
            fullWidth
            name={`contract-echeancier-toggle-${contrat.id}`}
          />
          {/* <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => toggleAvecEcheancier()}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader size={20} />
            ) : (
              <>
                <i
                  className={`fa ${
                    isAvecEcheancier ? "fa-times" : "fa-check"
                  } mr-2`}
                />
                {isAvecEcheancier ? "Désactiver" : "Activer"}
              </>
            )}
          </button> */}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <button
          className={`btn bg-transparent border-0 ${
            isAvecMensualisation ? "text-primary" : "text-default"
          } p-0`}
          style={{ cursor: "auto" }}
          disabled
        >
          <>
            <i
              className={`mr-2 ${
                isAvecMensualisation
                  ? "fa fa-check-circle"
                  : "far fa-circle text-black-50"
              }`}
            />
            {isAvecMensualisation
              ? "Avec mensualisation"
              : "Sans mensualisation"}
          </>
        </button>
        <button
          className="btn btn-default px-2 bg-transparent border-0"
          onClick={() => toast.info(mensualisationText, { autoClose: 15000 })}
        >
          <i className="fa fa-question-circle" />
        </button>
      </div>
    </>
  );
};

export default AvecEcheancierForm;
