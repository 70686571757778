import { useAtom } from "jotai";
import { useEffect } from "react";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import useManagers from "../../../hooks/useManagers/useManagers";
import {
  isLoadingPjListAtom,
  isPjListInitAtom,
  pjListAtom,
  pjListFiltersAtom,
} from "./atoms/pjRenewListAtoms";

const usePjRenewList = () => {
  const [pjList, setPjList] = useAtom(pjListAtom);
  const [isLoadingPjList, setIsLoadingPjList] = useAtom(isLoadingPjListAtom);
  const [pjListFilters, setPjListFilters] = useAtom(pjListFiltersAtom);
  const [isPjListInit, setIsPjListInit] = useAtom(isPjListInitAtom);
  const { managers } = useManagers();

  const getFormattedPjs = (apiPjs, applicableFilters) => {
    const newPjs = apiPjs.map((d) => {
      const beginDate = DateTime.now();
      const endDate = DateTime.fromISO(d.productData.endAt);
      const daysDifference = endDate.diff(beginDate, "days").days;
      return {
        ...d,
        comments: tools.populateNamesFromId(managers, d.comments),
        daysDifference,
      };
    });

    return applicableFilters.filters.renewableInNbDays > -1
      ? newPjs.sort((a, b) => a.daysDifference - b.daysDifference)
      : newPjs;
  };

  const getPjList = (overrideFilters = null) => {
    setIsLoadingPjList(true);
    if (overrideFilters) {
      setPjListFilters(overrideFilters);
    }
    const applicableFilters = overrideFilters || pjListFilters;
    dataService.post(
      `policies/renewals/search`,
      applicableFilters,
      (datas) => {
        setPjList({
          ...datas,
          data: getFormattedPjs(datas.data, applicableFilters),
        });
        setIsPjListInit(true);
      },
      (err) => {},
      () => setIsLoadingPjList(false)
    );
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const newFilters = {
      ...pjListFilters,
      filters: {
        ...pjListFilters.filters,
        policyId: null,
        [name]: value,
      },
    };
    getPjList(newFilters);
    setPjListFilters(newFilters);
  };

  const updatePage = (page) => {
    const newFilters = {
      ...pjListFilters,
      page,
    };
    getPjList(newFilters);
    setPjListFilters(newFilters);
  };

  return {
    pjList,
    isLoadingPjList,
    pjListFilters,
    actions: {
      getPjList,
      handleFilterChange,
      updatePage,
    },
  };
};

export default usePjRenewList;
