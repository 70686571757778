import React from "react";
import useCompanyItem from "../useCompanyItem";
import BPCompanyInfo from "./BPCompanyInfo/BPCompanyInfo";
import BPDocuments from "./BPDocuments/BPDocuments";
import BPPolicies from "./BPPolicies/BPPolicies";
import BPUsers from "./BPUsers/BPUsers";
import CompanyBottomPanel, {
  CloseCompanyBottomPanelButton,
} from "./CompanyBottomPanel/CompanyBottomPanel";
import BPBills from "./BPBills/BPBills";

const BottomPanel = () => {
  const { bottomPanelContent, actions, company, openedPanelName } =
    useCompanyItem();

  return !bottomPanelContent ? null : (
    <CompanyBottomPanel>
      <div className="mb-2">
        <h4
          className="py-0 mb-0 d-center align-items-center justify-content-start"
          style={{ gap: 5 }}
        >
          <button
            className="btn btn-link px-0 py-2"
            style={{ fontSize: 16 }}
            onClick={actions.closeBottomPanel}
          >
            <i className="fa fa-arrow-left mr-2" />
            {company.company}
          </button>
        </h4>
        <small className="text-default">siret : {company.siret}</small>
      </div>
      <div
        className="d-flex flex-row flex-wrap mb-2"
        style={{
          gap: 20,
        }}
      >
        <button
          className={`btn bg-transparent px-0 py-2 ${
            openedPanelName === "COMPANY"
              ? " border border-top-0 border-left-0 border-right-0 text-primary rounded-0 border-primary"
              : " border-0 text-muted"
          }`}
          onClick={() => actions.openBottomPanel("COMPANY")}
          title="Informations"
        >
          {/* <i className="fa fa-info-circle" />  */}
          Informations
        </button>

        <button
          className={`btn bg-transparent px-0 py-2 ${
            openedPanelName === "DOCUMENTS"
              ? " border border-top-0 border-left-0 border-right-0 text-primary rounded-0 border-primary"
              : " border-0 text-muted"
          }`}
          data-priv="bo_viewcompanydocs"
          onClick={() => actions.openBottomPanel("DOCUMENTS")}
          title="Documents"
        >
          {/* <i className="fa fa-file"></i>  */}
          Documents
        </button>

        {company.policies?.length > 0 && (
          <button
            className={`btn bg-transparent px-0 py-2 ${
              openedPanelName === "POLICIES"
                ? " border border-top-0 border-left-0 border-right-0 text-primary rounded-0 border-primary"
                : " border-0 text-muted"
            }`}
            onClick={() => actions.openBottomPanel("POLICIES")}
            title="Contrats"
          >
            {/* <i className="fa fa-file-contract"></i>  */}
            Contrats
          </button>
        )}

        <button
          className={`btn bg-transparent px-0 py-2 ${
            openedPanelName === "BILLS"
              ? " border border-top-0 border-left-0 border-right-0 text-primary rounded-0 border-primary"
              : " border-0 text-muted"
          }`}
          data-priv="bo_viewcontractdocs"
          onClick={() => actions.openBottomPanel("BILLS")}
          title="Factures"
        >
          {/* <i className="fa fa-file-invoice-dollar"></i>  */}
          Factures
        </button>

        <button
          className={`btn bg-transparent px-0 py-2 ${
            openedPanelName === "USERS"
              ? " border border-top-0 border-left-0 border-right-0 text-primary rounded-0 border-primary"
              : " border-0 text-muted"
          }`}
          onClick={() => actions.openBottomPanel("USERS")}
          title="Utilisateurs"
        >
          {/* <i className="fa fa-users"></i>  */}
          Utilisateurs
        </button>
      </div>
      <div
        style={{ display: bottomPanelContent === "COMPANY" ? "block" : "none" }}
      >
        <BPCompanyInfo />
      </div>
      <div
        style={{
          display: bottomPanelContent === "DOCUMENTS" ? "block" : "none",
        }}
      >
        <BPDocuments />
      </div>
      <div
        style={{
          display: bottomPanelContent === "POLICIES" ? "block" : "none",
        }}
      >
        <BPPolicies />
      </div>
      <div
        style={{
          display: bottomPanelContent === "BILLS" ? "block" : "none",
        }}
      >
        <BPBills />
      </div>
      <div
        style={{
          display: bottomPanelContent === "USERS" ? "block" : "none",
        }}
      >
        <BPUsers />
      </div>
      <div
        style={{
          display: bottomPanelContent === "EVENTS" ? "block" : "none",
        }}
      >
        <>évènements...</>
      </div>
      {/* 
      {(() => {
        switch (bottomPanelContent) {
          case "COMPANY":
            return <BPCompanyInfo />;
          case "DOCUMENTS":
            return <BPDocuments docType="COMPANY" />;
          case "POLICIES":
            return <BPPolicies />;
          case "BILLS":
            return <BPBills />;
          case "USERS":
            return <BPUsers />;
          case "EVENTS":
            return <>évènements...</>;

          default:
            return null;
        }
      })()} */}
    </CompanyBottomPanel>
  );
};

export default BottomPanel;
