import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../../../common/Loader";

const StatItem = ({ title, total, isLoading, link }) => {
  return (
    <StyledStatItem to={link} isEmpty={total == 0} className={`p-2`}>
      <div className="d-center flex-column statItem__content">
        <div className={`shadow statItem__content__total `}>
          {isLoading ? <Loader /> : total}
        </div>
        <div className="statItem__content__name">{title}</div>
      </div>
    </StyledStatItem>
  );
};

const StyledStatItem = styled(Link)`
  max-width: 150px;
  text-decoration: none !important;
  position: relative;

  & .statItem__content__total {
    width: 70px;
    height: 70px;
    background: ${({ isEmpty }) =>
      isEmpty ? "var(--color-light)" : "var(--color-primary)"};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ isEmpty }) => (isEmpty ? "black" : "white")};
    border-radius: 50%;
    font-size: 24px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(1.1);
      border: 1px solid var(--color-primary);
      opacity: 0.3;
      transition: 400ms;
    }
  }

  & .statItem__content__name {
    font-size: var(--font-size-default);
    margin-top: 10px;
    color: var(--color-primary);
    text-align: center;
  }

  &:hover {
    & .statItem__content__total {
      background: var(--color-success);
      color: white;
      &::after {
        transform: scale(1.5);
        opacity: 0;
      }
    }
    & .statItem__content__name {
      color: var(--color-success);
    }
  }
`;

export default StatItem;
