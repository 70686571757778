import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";
import useStore from "../../../context/useStore";
import useChat from "../../../hooks/useChat/useChat";
import useRole from "../../../hooks/useRole/useRole";
import Loader from "../Loader";

const ChatContainer = styled.div`
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: ${(props) =>
    props.isOpen ? "translateY(0)" : "translateY(calc(100% + 90px))"};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  min-width: 300px;
  min-height: 400px;
  max-width: 800px;
  max-height: 800px;
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  color: white;
  border: none;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  background: ${(props) => (props.isOpen ? "#007bff" : "#ffffff")};

  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
  }
`;

const ChatHeader = styled.div`
  padding: 15px;
  background: #007bff;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-default);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  font-size: 20px;
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Message = styled.div`
  padding: 10px;
  margin: 5px;
  max-width: 80%;
  border-radius: 10px;
  font-size: 15px;
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  background-color: ${(props) => (props.isUser ? "#007bff" : "#f0f0f0")};
  color: ${(props) => (props.isUser ? "white" : "black")};

  .typing-indicator {
    display: inline-block;
    margin-left: 4px;
    animation: typing 1.5s infinite;
  }

  @keyframes typing {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  /* Style pour le contenu Markdown */
  p {
    margin: 0;
    white-space: pre-wrap;
  }

  code {
    background: ${(props) =>
      props.isUser ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background: ${(props) =>
      props.isUser ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
    padding: 8px;
    border-radius: 4px;
    overflow-x: auto;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }

  a {
    color: ${(props) => (props.isUser ? "white" : "#007bff")};
    text-decoration: underline;
  }

  table {
    border-collapse: collapse;
    margin: 8px 0;

    th,
    td {
      border: 1px solid
        ${(props) =>
          props.isUser ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)"};
      padding: 4px 8px;
    }
  }

  blockquote {
    margin: 8px 0;
    padding-left: 12px;
    border-left: 3px solid
      ${(props) => (props.isUser ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)")};
  }
`;

const InputContainer = styled.div`
  padding: 15px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  &:focus {
    border-color: #007bff;
  }
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 86px;
  &:hover {
    background: #0056b3;
  }
`;

const StopButton = styled(SendButton)`
  background: #dc3545;

  &:hover {
    background: #c82333;
  }
`;

const ResizeHandle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  cursor: nw-resize;
  z-index: 1001;
  background: #007bff;
  border-radius: 0 0 5px 0;
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); */
  transition: background-color 0.2s;

  &:hover {
    background: #060da5;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: #ffffff;
  }

  &::before {
    top: 5px;
    left: 5px;
    width: 2px;
    height: 10px;
  }

  &::after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 2px;
  }
`;

const Chat = () => {
  const [state] = useStore();
  const { myRole } = useRole();
  const {
    messages,
    input,
    setInput,
    isLoading,
    isOpen,
    toggleChat,
    sendMessage,
    handleKeyPress,
    isStreaming,
    stopStream,
    width,
    height,
    messagesEndRef,
    chatContainerRef,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  } = useChat();

  // If user is not super admin, don't render the chat
  if (!state.auth.isAuthenticated || !myRole === "ADMIN") {
    console.log("User is not super admin");
    return null;
  }

  if (process.env.REACT_APP_CHAT_ENABLED !== "true") {
    console.log("Chat is disabled");
    return null;
  }

  return (
    <>
      <ToggleButton className="shadow" onClick={toggleChat} isOpen={isOpen}>
        <img src="/images/merlin-assistant.png" style={{ width: 54 }} />
      </ToggleButton>
      <ChatContainer
        ref={chatContainerRef}
        isOpen={isOpen}
        width={width}
        height={height}
      >
        <ChatHeader>
          Merlin, l'assistant Perceval
          <CloseButton onClick={toggleChat}>×</CloseButton>
        </ChatHeader>
        <MessagesContainer>
          {messages.map((message, index) => (
            <Message key={index} isUser={message.isUser}>
              {message.isUser ? (
                message.content
              ) : (
                <>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      p: ({ node, ...props }) => (
                        <p style={{ margin: 0 }} {...props} />
                      ),
                      a: ({ node, ...props }) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          {...props}
                        />
                      ),
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                  {message.isStreaming && (
                    <span className="typing-indicator">...</span>
                  )}
                </>
              )}
            </Message>
          ))}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Écrivez votre message..."
            disabled={isLoading}
          />
          {isStreaming ? (
            <StopButton onClick={stopStream}>Arrêter</StopButton>
          ) : (
            <SendButton onClick={sendMessage} disabled={isLoading}>
              {isLoading ? <Loader size={20} color="white" /> : "Envoyer"}
            </SendButton>
          )}
        </InputContainer>
        <ResizeHandle onMouseDown={handleMouseDown} />
      </ChatContainer>
    </>
  );
};

export default Chat;
