import { Provider } from "jotai";
import { useEffect } from "react";
import useStore from "../../../../../../../../../context/useStore";
import Control from "../../../../../../../../common/Controls/Control";
import useCompanyItem from "../../useCompanyItem";
import PolicyItem from "./PolicyItem/PolicyItem";
import useCompanyPolicies from "./useCompanyPolicies";

const BPPolicies = () => {
  const { company, bottomPanelParams, actions } = useCompanyItem();

  const {
    companyPolicies,
    initCompanyPolicies,
    onChangePolicyStatusFilter,
    policyStatusFilter,
  } = useCompanyPolicies();
  const [state] = useStore();

  useEffect(() => {
    initCompanyPolicies(company.policies);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      checkPolicySelected();
    }, 500);
  }, [companyPolicies, bottomPanelParams]);

  const checkPolicySelected = () => {
    if (bottomPanelParams) {
      let firstItemFound = false;
      for (var key in companyPolicies) {
        const policy = companyPolicies[key];
        let elem = document.querySelector(`.policy-${policy.id}`);
        if (elem) {
          elem.classList.remove("animated");
          elem.classList.remove("pulse");
          elem.classList.remove("highlight-shadow");
        }
        if (
          elem &&
          bottomPanelParams.productType == policy.product.productType
        ) {
          if (!firstItemFound && companyPolicies.length > 1) {
            console.log(bottomPanelParams, policy);
            firstItemFound = true;
            let parent = getScrollParent(elem);

            if (parent && parent.classList.contains("companyBottomPanel")) {
              parent.scroll(0, elem.offsetTop);
            }
          }
          if (elem) {
            elem.classList.add("animated");
            elem.classList.add("pulse");
            elem.classList.add("faster");
            elem.classList.add("highlight-shadow");
            elem.style.animationDelay = "500ms";
          }
        }
      }
    }
  };

  function getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }

  let contractStatuses = state.constants.items.POLICIES_STATUS.filter(
    (d) => d.id != 0
  );

  const filteredPolicies = companyPolicies.filter((policy) => {
    if (policyStatusFilter.length > 0) {
      return policyStatusFilter.includes(policy.status);
    }
    return true;
  });

  return (
    <div className="row mx-0">
      <div className="col-12 mt-2 px-0">
        <Control
          type="checkBtnList"
          name="contractStatus"
          datas={contractStatuses}
          dataLabel="name"
          dataIndex="id"
          change={onChangePolicyStatusFilter}
          value={policyStatusFilter}
          btnInline
          noMarginOnFormGroup
          noPaddingOnFormGroup
          noBg
        />
      </div>
      {filteredPolicies.map((policy) => {
        return (
          <Provider>
            <PolicyItem
              companyData={company}
              policyData={policy}
              key={`pol${policy.id}`}
              onToggleAvecEcheancier={actions.onToggleAvecEcheancier}
            />
          </Provider>
        );
      })}
      {filteredPolicies.length == 0 && (
        <p className="col-12 d-flex justify-content-center">
          Aucun contrat trouvé
        </p>
      )}
    </div>
  );
};

export default BPPolicies;
