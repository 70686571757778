import React from "react";
import tools from "../../../../../../helpers/tools";
import EmailLink from "../../../../../common/CommentList/common/EmailLink/EmailLink";
import PhoneLink from "../../../../../common/CommentList/common/PhoneLink/PhoneLink";
import SaveBtn from "../../../../../common/SaveBtn";
import useClientItem from "../useClientItem";
import useContextualTimeline from "../useContextualTimeline/useContextualTimeline";
import useUserInfos from "./useUserInfos";

const UserStatus = () => {
  const { client } = useClientItem();

  const textColorCN = client.isActivated ? "text-info" : "text-danger";
  const iconCN = client.isActivated ? "fa-check" : "fa-times";
  const text = client.isActivated ? "Compte activé" : "Compte non activé";

  return (
    <div className={`status-tooltip ${textColorCN}`}>
      <i className={`fa  ${iconCN}`} />
      <span
        className="status-tooltip-content"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    </div>
  );
};

const ActivationStatus = () => {
  const { client } = useClientItem();

  const tokenAt = client.activationTokenSendedAt;
  const textColorCN = tokenAt ? "text-info" : "text-danger";
  const text = tokenAt ? (
    <>
      Activation envoyée <br />
      {tools.formatDate(tokenAt, "dd.MM.yy HH:mm")}
    </>
  ) : (
    "Activation non envoyée"
  );

  return (
    <div
      className={`status-tooltip ${textColorCN}`}
      data-toggle="tooltip"
      data-placement="top"
      title={text}
    >
      <i className={`fa fa-envelope`} />
      <span
        className="status-tooltip-content"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    </div>
  );
};

const AvisGoogleStatus = () => {
  const { client } = useClientItem();

  const emailSentAt = client.emailAvisGoogleSentAt;
  const textColorCN = emailSentAt ? "text-info" : "text-danger";
  const text = emailSentAt ? (
    <>
      Email envoyé <br />
      {tools.formatDate(emailSentAt, "dd.MM.yy HH:mm")}
    </>
  ) : (
    "Email avis Google non envoyé"
  );

  return (
    <div
      className={`status-tooltip ${textColorCN}`}
      data-toggle="tooltip"
      data-placement="top"
      title={text}
    >
      <i
        className="fa fa-star"
        style={{ fontSize: 14, position: "relative", bottom: 1 }}
      />
      <span
        className="status-tooltip-content"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    </div>
  );
};

const UserInfos = () => {
  const {
    client,
    isSendingActivation,
    isSendingAvisGoogle,
    isActivating,
    isUnlocking,
    isBlockUser,
    isBlocking,
    actions,
  } = useUserInfos();
  const { openTimeline } = useContextualTimeline();

  const actionBtnCN =
    "btn-default dropdown-item border font-weight-normal mx-0 mt-0 rounded-0 border-0 btn-sm w-100 d-center";

  return (
    <div className="row">
      <div className="col-12 col-md-8">
        {isBlockUser ? (
          <span className="badge badge-danger mb-1">Compte bloqué</span>
        ) : (
          ""
        )}
        <div className="pb-1">
          <strong style={{ fontSize: 17 }}>
            <i
              className="fa fa-user mr-2 position-relative"
              style={{ fontSize: 15, top: -1 }}
            />
            {tools.getFullName(client)}
          </strong>
        </div>
        <div>
          <PhoneLink phone={client.phone} />
        </div>
        <div className="mb-1 d-flex flex-wrap" style={{ gap: 10 }}>
          <EmailLink email={client.email} />
          <UserStatus />
          <ActivationStatus />
          <AvisGoogleStatus />
        </div>
        <div className="text-muted">
          créé le {tools.formatDate(client.createdAt, "dd.MM.yy")}
        </div>
        {/* </div>

      <div className="col-12 col-md-4"> */}

        <div className="dropdown  mt-1">
          <button
            className="btn btn-default dropdown-toggle position-relative border btn-sm"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Actions du compte
            <i className="fa fa-caret-down ml-2" />
          </button>
          <div
            className="dropdown-menu py-0"
            aria-labelledby="dropdownMenuButton"
          >
            <button
              data-priv="bo_updatecustomer"
              className={`${actionBtnCN} btn btn-light`}
              onClick={actions.openUserForm}
            >
              Modifier les informations
            </button>
            <SaveBtn
              dataPriv="bo_sendactivationmail"
              className={actionBtnCN}
              type="light"
              save={actions.sendActivation}
              isSaving={isSendingActivation}
              text="Renvoyer l'email d'activation"
            />
            <SaveBtn
              dataPriv="bo_sendactivationmail"
              className={actionBtnCN}
              type="light"
              save={actions.activateMail}
              isSaving={isActivating}
              text="Forcer l'activation"
            />
            <SaveBtn
              dataPriv="bo_envoyermailavisgoogle"
              className={actionBtnCN}
              type="light"
              save={actions.sendAvisGoogle}
              isSaving={isSendingAvisGoogle}
              text="Envoyer l'email Avis Google"
            />
            <SaveBtn
              dataPriv="bo_resetpasswordtries"
              className={actionBtnCN}
              type="light"
              save={actions.unlockAccount}
              isSaving={isUnlocking}
              text="Déverrouiller l'accès au compte"
            />
            <button
              className="btn btn-light border-0  w-100 font-weight-normal btn-sm"
              onClick={() =>
                openTimeline({
                  model: "users",
                  id: client.id,
                  searchString: "",
                })
              }
            >
              Événements du compte
            </button>
            {isBlockUser ? (
              <SaveBtn
                dataPriv="bo_lockaccount"
                className={actionBtnCN}
                type="default"
                save={actions.blockUserAccount}
                isSaving={isBlocking}
                text="Débloquer le compte"
              />
            ) : (
              <SaveBtn
                dataPriv="bo_lockaccount"
                className={`${actionBtnCN} text-white`}
                type="danger"
                save={actions.blockUserAccount}
                isSaving={isBlocking}
                text="Bloquer le compte"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfos;
